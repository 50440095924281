import React, { useEffect, useState, useRef } from "react";
import './PDFPlayer.scss';
import Conf from 'utils/Conf';
import Fullscreen from 'components/Fullscreen';
import { usePdf } from '@mikecousins/react-pdf';
import _ from 'lodash';

let s=1;
let prevDiff=0;
    
function PDFPlayer({pdf}) {
  const [page, setPage] = useState(1);
  const [done, setDone] = useState(false);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState([0,0]);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const { pdfDocument } = usePdf({
    file: `${Conf.apiUrl}/assets/${pdf.id}`,
    page,
    canvasRef,
    scale,
    onPageRenderSuccess:()=>{
      if (!done) {
        setDone(true);
        setScale(sc=>{
          const ratioW=(canvasRef.current.clientWidth/sc)/containerRef.current.clientWidth;
          const ratioH=(canvasRef.current.clientHeight/sc)/containerRef.current.clientHeight;
          s=Math.min(1/ratioW,1/ratioH)
          return s;
        });
        canvasRef.current.style.transform='none';
        prevDiff=0;
        setOffset([0,0]);
      }
    }
  });
  useEffect(()=>{
    s=1;
    const container=containerRef;
    const debounced=_.debounce(setScale,200);
    const handler=(e)=>{
      e.preventDefault();
      if (e.deltaY<0) s=Math.min(10,s*1.2);
      if (e.deltaY>0) s=Math.max(0.2,s*0.83);
      debounced(s);
    }
    container.current.addEventListener("wheel", handler, { passive:false });
    return ()=>container.current && container.current.removeEventListener("wheel", handler);
  },[setScale]);
  useEffect(()=>{
    const container=containerRef;
    let x0=0;
    let y0=0;
    let dx=0;
    let dy=0;
    const evCache=[];
    prevDiff=0;
    const throttled=_.throttle(setScale,200);
    const pointerMoveHandler=(e)=>{
      for (var i = 0; i < evCache.length; i++) {
        if (e.pointerId === evCache[i].pointerId) {
          evCache[i] = e;
          break;
        }
      }
      if (evCache.length === 2) {
        // Calcule la distance entre les deux pointeurs
        var curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);
    
        if (prevDiff > 0) {
          if (curDiff > prevDiff*1.01) {
            // La distance entre les deux pointeurs a augmenté
            console.log("Pinch moving OUT -> Zoom in", e);
          }
          if (curDiff < prevDiff*0.99) {
            // La distance entre les deux pointeurs a diminué
            console.log("Pinch moving IN -> Zoom out", e);
          }
          s=Math.max(0.2,Math.min(10,s+0.1*(curDiff-prevDiff)/canvasRef.current.clientWidth));
          throttled(s);
          console.log(s);
        }
        console.log(curDiff,prevDiff,s);
        // Met en cache la distance pour les événements suivants
      } else {
        let x=e.clientX;
        let y=e.clientY;
        dx=x/canvasRef.current.clientWidth-x0;
        dy=y/canvasRef.current.clientHeight-y0;
        canvasRef.current.style.transform=`translate3d(${dx*100}%,${dy*100}%,0)`;
      }
    }
    const pointerDownHandler=(e)=>{
      e.preventDefault();
      console.log('touchstart');
      evCache.push(e);
      if (evCache.length === 2) {
        // Calcule la distance entre les deux pointeurs
        prevDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);
      } else {
        let x=e.clientX;
        let y=e.clientY;
        x0=x/canvasRef.current.clientWidth-offset[0];
        y0=y/canvasRef.current.clientHeight-offset[1];
        document.addEventListener('pointermove',pointerMoveHandler);
        document.addEventListener('pointerup', pointerUpHandler);
      }
    }
    const pointerUpHandler=(e)=>{
      document.removeEventListener('pointermove',pointerMoveHandler);
      document.removeEventListener('pointerup', pointerUpHandler);
      if (evCache.length === 2) {
        prevDiff=0;
      }
      const idx=evCache.indexOf(o=>o.pointerId===e.pointerId);
      if (idx!==-1) evCache.splice(idx,1);
      setOffset([dx,dy])
    }
    container.current.addEventListener('pointerdown', pointerDownHandler);
    return ()=>{
      container.current && container.current.removeEventListener('pointerdown', pointerDownHandler);
    }
  },[setScale,offset]);
  return <div className="pdf-player">
    <div ref={containerRef}>
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} className={done ? 'ready' : ''}/>
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav className="pager-container">
          <ul className="pager">
            <li className="previous">
              <button disabled={page === 1} onClick={() =>{setDone(false);setPage(page - 1);}}>
                &lsaquo;
              </button>
            </li>
            <li className="next">
              <button
                disabled={page === pdfDocument.numPages}
                onClick={() => {setDone(false);setPage(page + 1);}}
              >
                &rsaquo;
              </button>
            </li>
          </ul>
        </nav>
      )}
      <Fullscreen/>
    </div>
  </div>;
}

export default PDFPlayer;
