import React, { useState, useContext, useEffect } from "react";
import './MainList.scss';
import { useQuery } from '@apollo/client';
import {
  Link
} from "react-router-dom";
import CartoucheInfos from 'components/CartoucheInfos';
import Visuel from 'components/Visuel';
import Utils from 'utils/Utils';
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Pagination from './Pagination';
import Queries from 'utils/Queries';
import shim from 'string.prototype.matchall/shim';
shim();

function MainList() {
  const { lang, filter, setTick } = useContext(SettingsContext);
  const { data } = useQuery(Queries.mainlist);
  useEffect(()=>setTick(t=>t+1),[data,setTick]);
  const videos=data ? data.videos : [];
  const audios=data ? data.audios : [];
  const images=data ? data.images : [];
  const textes=data ? data.textes : [];
  const multimedias=data ? data.multimedias : [];
  const tab=[...videos, ...audios, ...textes, ...images, ...multimedias];
  const { search } = filter;
  //tag
  const tagRegex=/(^|\s)#([^\s]+)/g;
  const tagsRes=Utils.accentsTidyLw(search).matchAll(tagRegex) || [];
  const tags=[...tagsRes].map((t)=>t[2]);
  //artiste
  const artisteRegex=/(^|\s)@([^\s]+)/g;
  const artistesRes=Utils.accentsTidyLw(search).matchAll(artisteRegex) || [];
  const artistes=[...artistesRes].map((t)=>t[2]);
  //type
  const typeRegex=/(^|\s):([^\s]+)/g;
  const typeRes=Utils.accentsTidyLw(search).matchAll(typeRegex) || [];
  const types=[...typeRes].map((t)=>t[2]);
  //expos
  const expoRegex=/(^|\s)\+([^\s]+)/g;
  const expoRes=Utils.accentsTidyLw(search).matchAll(expoRegex) || [];
  const expos=[...expoRes].map((t)=>t[2]);
  const searchRegex=/(^|\s)[^#@:+\s][^\s]+/g;
  const searchRes=Utils.accentsTidyLw(search).match(searchRegex) || [];
  const words=[...searchRes].filter((s)=>
      tags.indexOf(s)===-1
      && artistes.indexOf(s)===-1
      && types.indexOf(s)===-1
      && expos.indexOf(s)===-1
  );
  const filtered= filter ? tab.reduce((acc,item)=>{
      const re=new RegExp(Utils.accentsTidyLw(words.join(' ')),"gi");
      let found=true;
      let stringFound=false;
      Object.keys(item).forEach((k, j) => {
        if (k!=='__typename' && (typeof item[k]==='string')) {
          stringFound=stringFound || Utils.accentsTidyLw(item[k]).search(re)!==-1;
        }
        if (k==='translations'){
          item.translations.forEach((tr, l) => {
              Object.keys(tr).forEach((trk, tri) => {
                  if ((typeof tr[trk]==='string')) {
                    stringFound=stringFound || Utils.accentsTidyLw(tr[trk]).search(re)!==-1;
                  }
              });
          });
        }
        if (k==='artistes'){
          item.artistes.forEach((artiste, l) => {
              if (artiste.artistes_id) {
                stringFound=stringFound || Utils.accentsTidyLw(artiste.artistes_id.name).search(re)!==-1;
              }
          });
        }
      });
      found= found && stringFound;
      tags.forEach((t)=>{
          found= found && item.tags.reduce((acc,tag)=>acc || (tag.tags_id ? Utils.accentsTidyLw(tag.tags_id.tag.replace(/\s/g,'-'))===Utils.accentsTidyLw(t) : false ),false);
      });
      artistes.forEach((t)=>{
          found= found && item.artistes.reduce((acc,artiste)=>acc || (artiste.artistes_id ? Utils.accentsTidyLw(artiste.artistes_id.name.replace(/\s/g,'_'))===Utils.accentsTidyLw(t) : false ),false);
      });
      let typeTest=false;
      types.forEach((t)=>{
          typeTest= typeTest || (item.type===t || item.__typename===t);
      });
      if (types.length>0) found= found && typeTest;
      expos.forEach((t)=>{
        found= found && item.expositions.reduce((acc,expo)=>{
          return acc || (expo.expositions_id ? Utils.accentsTidyLw(expo.expositions_id.title.replace(/\s/g,'_'))===Utils.accentsTidyLw(t) : false );
        },false);
      });
      return found ? [...acc,item] : acc;
  },[]) : tab;
  const renderInnerItem=(classes,cartoucheData,item)=><div className={classes}>
      <Hidden smUp><div className="spacer"></div></Hidden>
      <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
              <Visuel visuel={item.visuel}/>
          </Grid>
          <Grid item xs={12} sm={8}>
              <CartoucheInfos data={cartoucheData} splitStat/>
          </Grid>
      </Grid>
  </div>;
  const renderVideo=(item, first=false) => {
      const classes= first ? "main-list-item first" : "main-list-item";
      const cartoucheData=Utils.renderItem(item,lang);
      return renderInnerItem(classes,cartoucheData,item);
  }
  const renderAudio=(item, first=false) => {
    const classes= first ? "main-list-item first" : "main-list-item";
    const cartoucheData=Utils.renderItem(item,lang);
    return renderInnerItem(classes,cartoucheData,item);
  }
  const renderTexte=(item, first=false) => {
    const classes= first ? "main-list-item first" : "main-list-item";
    const cartoucheData=Utils.renderItem(item,lang);
    return renderInnerItem(classes,cartoucheData,item);
  }
  const renderImages=(item, first=false) => {
      const classes= first ? "main-list-item first" : "main-list-item";
      const cartoucheData=Utils.renderItem(item,lang);
      return renderInnerItem(classes,cartoucheData,item);
  }
  const renderMultimedias=(item, first=false) => {
      const classes= first ? "main-list-item first" : "main-list-item";
      const cartoucheData=Utils.renderItem(item,lang);
      return renderInnerItem(classes,cartoucheData,item);
  }
  const [ page, setPage] = useState(1);
  useEffect(()=>window.scroll(0,84),[page]);
  useEffect(()=>setPage(1),[filter]);
  const nb=filtered ? filtered.length : 0;
  return data ? <div className="main-list">
      {nb>0 && <Pagination page={page} nb={nb} perPage={Conf.perPage} onPageChange={setPage}/>}
      { filtered && filtered.sort((a,b)=>a.date_created<b.date_created ? 1 : -1).map((item,i)=>{
          const from=(page-1)*Conf.perPage+1;
          const to=Math.min(nb,page*Conf.perPage);
          if (i+1>=from && i+1<=to) {
              const itemKey=item.slugs && item.slugs[0] ? item.slugs[0].slug : item.id;
              return <React.Fragment key={i}>
                  {item.__typename==='videos' && <Link to={`/video/${itemKey}`}>{renderVideo(item,i+1===from)}</Link>}
                  {item.__typename==='audios' && <Link to={`/audio/${itemKey}`}>{renderAudio(item,i+1===from)}</Link>}
                  {item.__typename==='textes' && <Link to={`/texte/${itemKey}`}>{renderTexte(item,i+1===from)}</Link>}
                  {item.__typename==='images' && <Link to={`/images/${itemKey}`}>{renderImages(item,i+1===from)}</Link>}
                  {item.__typename==='multimedias' && <Link to={`/multimedia/${itemKey}`}>{renderMultimedias(item,i+1===from)}</Link>}
              </React.Fragment>
          } else return '';
      })}
      {nb>Conf.perPage && <Pagination page={page} nb={nb} perPage={Conf.perPage} onPageChange={setPage}/>}
  </div> : '';
}

export default MainList;
