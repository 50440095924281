import React, {useContext,useState,useEffect} from "react";
import './Suggestion.scss';
import { useQuery } from '@apollo/client';
import { SettingsContext } from 'Providers/SettingsProvider';
import CartoucheInfos from 'components/CartoucheInfos';
import Grid from '@mui/material/Grid';
import {
  Link
} from "react-router-dom";
import Utils from 'utils/Utils';
import Queries from 'utils/Queries';

function Suggestion({currentItem}) {
    const { data } = useQuery(Queries.suggestion);
    const { t, lang } = useContext(SettingsContext);
    const cartoucheMain=[
        {key:'suggestion',value:t('autres-medias-a-voir')},
    ];
    const [filteredItems, setFilteredItems]=useState([]);
    useEffect(()=>{
        const videos=data ? data.videos : [];
        const audios=data ? data.audios : [];
        const images=data ? data.images : [];
        const multimedias=data ? data.multimedias : [];
        const items=[...videos, ...audios, ...images, ...multimedias];
        setFilteredItems(Utils.shuffle(items).filter((item)=>{
            return item.id!==currentItem.id || item.__typename!==currentItem.__typename;
        }));
    },[data,currentItem])
    return <div className="suggestion">
      <CartoucheInfos data={cartoucheMain}/>
      <Grid container className="suggestion-items" spacing={2}>
          {filteredItems.map((item,i)=>{
              const itemData=Utils.renderItem(item,lang);
              const itemKey=item.slugs && item.slugs[0] ? item.slugs[0].slug : item.id;
              return i<4 ? <Grid key={i} item xs={12} sm={6} md={3}>
                  {item.__typename==='videos' && <Link to={`/video/${itemKey}`}><CartoucheInfos data={itemData}/></Link>}
                  {item.__typename==='audios' && <Link to={`/audio/${itemKey}`}><CartoucheInfos data={itemData}/></Link>}
                  {item.__typename==='images' && <Link to={`/images/${itemKey}`}><CartoucheInfos data={itemData}/></Link>}
                  {item.__typename==='textes' && <Link to={`/textes/${itemKey}`}><CartoucheInfos data={itemData}/></Link>}
                  {item.__typename==='multimedias' && <Link to={`/multimedia/${itemKey}`}><CartoucheInfos data={itemData}/></Link>}
              </Grid> : '';
          })}
      </Grid>
    </div>;
}

export default Suggestion;
