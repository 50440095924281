import React from 'react';
import './index.css';

import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Conf from 'utils/Conf';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: Conf.apiUrl+"/graphql",
});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: Conf.authToken ? `Bearer ${Conf.authToken}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          items: {
            merge: (existing, incoming, opts) =>
              opts.mergeObjects(existing, incoming),
          },
        },
      },
    },
  })
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ApolloProvider client={client}>
  <App />
</ApolloProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
