import React, {useContext} from "react";
import './MenuLeft.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import Expo from 'components/Expo';
import Type from 'components/Type';
import Hidden from '@mui/material/Hidden';
import Queries from 'utils/Queries';

function MenuLeft() {
  const { data } = useQuery(Queries.menuleft);
  let expositionsFiltered=[];
  const expositions=data ? data.expositions : [];
  const now=DateTime.now();
  expositions.forEach((expo)=>{
    const nOeuvres=
      expo.o_multimedia.filter((o)=>o.multimedias_id && o.multimedias_id.status==='published').length
      +
      expo.o_audios.filter((o)=>o.audios_id && o.audios_id.status==='published').length
      +
      expo.o_videos.filter((o)=>o.videos_id && o.videos_id.status==='published').length
      +
      expo.o_images.filter((o)=>o.images_id && o.images_id.status==='published').length;
    if (
      nOeuvres>0
      &&
      DateTime.fromISO(expo.date_from)<=now
      &&
      DateTime.fromISO(expo.date_to)>=now
    ) {
      if (!expositionsFiltered.find((exp)=>exp.id===expo.id)) expositionsFiltered.push(expo);
    }
  });
  const { t } = useContext(SettingsContext);
  const tab=[
    {
        titre: t('videos'),
        type: 'videos',
        icon: 'video',
    },
    {
        titre: t('audios'),
        type: 'audios',
        icon: 'audio',
    },
    {
        titre: t('images'),
        type: 'images',
        icon: 'image',
    },
    {
        titre: t('textes'),
        type: 'textes',
        icon: 'texte',
    },
    {
        titre: t('multimedias'),
        type: 'multimedias',
        icon: 'multimedia',
    },
  ];
  let classes='menu-left';
  return <Hidden xsDown>
      <div className={classes}>
          {expositionsFiltered.map((expo,i)=>{
              return <Expo key={expo.id} expo={expo} toggle={true}/>;
          })}
          {tab.length>0 && <div className="menu-left-surtitre">{t('filtres')}</div>}
          {tab.map((item,i)=><Type key={i} type={item}/>)}
      </div>
    </Hidden>;
}

export default MenuLeft;
