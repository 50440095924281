import React, {useContext} from "react";
import './CartoucheInfos.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@mui/material/Grid';
import Utils from 'utils/Utils';

function CartoucheInfos({data, splitStat=false}) {
  const { t } = useContext(SettingsContext);
  const renderItem=(item,i,Picto)=><React.Fragment key={i}>
      {item.value!=='' && item.key && <div className="cartouche-infos-label">{t(item.key)}</div>}
      {item.value!=='' && item.value && item.key_tr && <div className="cartouche-infos-label">{item.key_tr}</div>}
      {item.value!=='' && item.value && <div className="cartouche-infos-value">{item.value}</div>}
      {item.text!=='' && item.text && <div className="cartouche-infos-texte" dangerouslySetInnerHTML={{__html:item.text}}></div>}
      {item.ps!=='' && item.ps && <div className="cartouche-infos-ps" dangerouslySetInnerHTML={{__html:item.ps}}></div>}
      {item.icon && <div className="cartouche-infos-icon"><Picto/></div>}
  </React.Fragment>;
  let classes="cartouche-infos";
  if (splitStat) classes+=" splitstat"
  return data ? <div className={classes}>
      {!splitStat && data.map((item,i)=>{
        let Picto=item.icon ? Utils.pictos[item.icon] : null;
        return renderItem(item,i,Picto);
      })}
      {splitStat && <React.Fragment>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
              {data.map((item,i)=>{
                if (i<data.length-2) {
                  let Picto=item.icon ? Utils.pictos[item.icon] : null;
                  return renderItem(item,i,Picto);
                }
                return '';
              })}
              </Grid>
              <Grid item xs={12} sm={4}>
              {data.map((item,i)=>{
                if (i>=data.length-2 && i<data.length-1) {
                  let Picto=item.icon ? Utils.pictos[item.icon] : null;
                  return renderItem(item,i,Picto);
                }
                return '';
              })}
              </Grid>
          </Grid>
          {data.map((item,i)=>{
            if (i>=data.length-1) {
              let Picto=item.icon ? Utils.pictos[item.icon] : null;
              return renderItem(item,i,Picto);
            }
            return '';
          })}
      </React.Fragment>}
  </div> : '';
}

export default CartoucheInfos;
