import React, { useRef, useState } from "react";
import {ReactComponent as FullscreenIn} from 'assets/fullscreen.svg';
import {ReactComponent as FullscreenOut} from 'assets/fullscreen_out.svg';
import './Fullscreen.scss';
function Fullscreen({onToggle=()=>{}}) {
  const ref=useRef();
  const [ fs, setFs ] = useState(false);
  const toggleFullscreen=()=>{
      if (ref.current && ref.current.parentNode) {
          if (document.fullscreenElement) {
            if (document.exitFullscreen) {
              document.exitFullscreen();
              setFs(false);
            }
          } else {
            if (ref.current.parentNode.requestFullscreen) {
                ref.current.parentNode.requestFullscreen();
                setFs(true);
            }
          }
          onToggle();
      }
  }
  return <div className="fullscreen-btn" onClick={toggleFullscreen} onTouchStart={toggleFullscreen} ref={ref}>{!fs && <FullscreenIn/>}{fs && <FullscreenOut/>}</div>;
}
export default Fullscreen;
