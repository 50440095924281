import React, {useContext} from "react";
import Grid from '@mui/material/Grid';
import LangSwitch from 'components/LangSwitch';
import Hidden from '@mui/material/Hidden';
import Rechercher from 'components/Rechercher';
import './TopBar.scss';
import {
  Link
} from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';
function TopBar() {
    const { settings, t} = useContext(SettingsContext);
    let classes='topbar';
    return <div className={classes}>
          <div className="topbar-inner">
            <Hidden smDown>
            <Grid container alignContent="flex-end">
                <Grid item md={3}>
                    <Link to="/" className="topbar-mainlink playlist-link">{settings.sitename}</Link>
                </Grid>
                <Grid item md={9}>
                    <Grid container alignContent="flex-end" spacing={2}>
                        <Grid item xs={8}>
                            <Rechercher/>
                        </Grid>
                        <Grid item xs={4}>
                            <LangSwitch/>
                            <a href="https://www.centredartlelait.com" className="topbar-mainlink front-link">{t('le-lait')}</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Hidden>
            <Hidden mdUp>
            <Grid container alignContent="flex-end" className="xs">
                <Grid item xs={12} className="xs-title">
                    <Link to="/" className="topbar-mainlink playlist-link">{settings.sitename}</Link>
                </Grid>
                <Grid container alignContent="flex-end" spacing={2} className="xs-utils">
                    <Grid item xs={8}>
                        <Rechercher/>
                    </Grid>
                    <Grid item xs={4}>
                        <LangSwitch align='right'/>
                    </Grid>
                </Grid>
            </Grid>
            </Hidden>
        </div>
    </div>;
}

export default TopBar;
