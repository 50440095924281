import React, { useContext } from "react";
import './Pagination.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function Pagination({page,nb,perPage,onPageChange}) {
  const { t } = useContext(SettingsContext);
  const from=(page-1)*perPage+1;
  const to=Math.min(nb,page*perPage);
  const nbPages=parseInt(Math.ceil(nb/perPage));
  return <div className="pagination">
      {nb===1 && <>{nb} {t('oeuvre')}</>}
      {nb>1 && <>{nb} {t('oeuvres')}</>}
      {nbPages>1 && <div className="pagination-right">
          <span className="chevron" onClick={()=>onPageChange(1)}>{'<<'}</span>
          <span onClick={()=>onPageChange(Math.max(1,page-1))}>{'<'}</span>
          <span className="page">{t('page')} {page}/{nbPages}</span>
          <span onClick={()=>onPageChange(Math.min(nbPages,page+1))}>{'>'}</span>
          <span className="chevron" onClick={()=>onPageChange(nbPages)}>{'>>'}</span>
      </div>}
  </div>;
}

export default Pagination;
