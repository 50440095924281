import React from "react";
import Grid from '@mui/material/Grid';
import MenuLeft from 'components/MenuLeft';
import MainList from 'components/MainList';
import './Accueil.scss'

function Accueil() {
  return (<div className="accueil">
      <div className="main-container">
          <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                  <MenuLeft/>
              </Grid>
              <Grid item xs={12} md={9}>
                  <MainList/>
              </Grid>
          </Grid>
      </div>
  </div>);
}
export default Accueil;
