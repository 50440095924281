import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TopBar from 'components/TopBar/TopBar';
import Footer from 'components/Footer/Footer';
import AllRoutes from 'components/Router/AllRoutes';
import ScrollToTop from 'components/Router/ScrollToTop';
import Accueil from 'components/Accueil/Accueil';
import Mask from 'components/Mask';
import SettingsProvider from "Providers/SettingsProvider";

const theme = createTheme({
  palette: {
    text: {
      primary: '#FFFF',
      secondary: '#FFFF',
      disabled: '#FFFF',
      hint: '#FFFF',
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#FFF',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#AAA',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiIconButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#FFFF',
      },
    },
    MuiGrid: {
      item: {
        position:'relative',
      }
    }
  },
});
function App() {
  const localRoutes=[
      <Route key='main' exact path="/" element={<Accueil/>}/>,
  ];
  return (
    <ThemeProvider theme={theme}>
    <SettingsProvider>
    <Router>
      <TopBar/>
      <AllRoutes localRoutes={localRoutes}/>
      <Footer/>
      <ScrollToTop/>
    </Router>
    <Mask/>
    </SettingsProvider>
    </ThemeProvider>
  );
}

export default App;
