import React, { useState } from "react";
import './DiapoSonPlayer.scss';
import Conf from 'utils/Conf';
import Fullscreen from 'components/Fullscreen';

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, A11y]);


function DiapoSonPlayer({images, audio}) {
  const [index, setIndex ]= useState(0);
  const handleSlideChange=(swiper)=>{
    setIndex(swiper.activeIndex)
  }
  return <div className="images-player">
    <Swiper
    navigation
    onSlideChange={handleSlideChange}
    >
      {images.map((img,i)=>{
          return <SwiperSlide key={img.directus_files_id.id}><img alt={img.directus_files_id.title} src={`${Conf.apiUrl}/assets/${img.directus_files_id.id}?key=16-9`}/></SwiperSlide>;
      })}
    </Swiper>
    <span className="images-player-compteur">{index+1} / {images.length}</span>
    {audio && <div className="controls">
      <audio src={`${Conf.apiUrl}/assets/${audio.directus_files_id.id}`} controls/>
    </div>}
    <Fullscreen/>
  </div>;
}

export default DiapoSonPlayer;
