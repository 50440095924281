import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Route,
  Routes,
} from "react-router-dom";
import Video from 'components/Video';
import Texte from 'components/Texte';
import Audio from 'components/Audio';
import Images from 'components/Images';
import Multimedia from 'components/Multimedia';
import Queries from 'utils/Queries';

function AllRoutes ({
  localRoutes=[],
  lang
}) {
  const { data } = useQuery(Queries.allroutes);
  const videos = data ? data.videos : [];
  const audios = data ? data.audios : [];
  const textes = data ? data.textes : [];
  const images = data ? data.images : [];
  const multimedias = data ? data.multimedias : [];
  let res=[];
  videos.forEach((video, i) => {
    res.push(<Route key={`/video/${video.id}`} path={`/video/${video.id}`} element={<Video id={video.id} lang={lang}/>}/>);
    if (video.slugs && video.slugs.length>0) {
      video.slugs.forEach((slug, i) => {
        res.push(<Route key={`/video/${slug.slug}`} path={`/video/${slug.slug}`} element={<Video id={video.id} lang={lang}/>}/>);
      });
    }
  });
  textes.forEach((texte, i) => {
    res.push(<Route key={`/texte/${texte.id}`} path={`/texte/${texte.id}`} element={<Texte id={texte.id} lang={lang}/>}/>);
    if (texte.slugs && texte.slugs.length>0) {
      texte.slugs.forEach((slug, i) => {
        res.push(<Route key={`/texte/${slug.slug}`} path={`/texte/${slug.slug}`} element={<Texte id={texte.id} lang={lang}/>}/>);
      });
    }
  });
  audios.forEach((audio, i) => {
    res.push(<Route key={`/audio/${audio.id}`} path={`/audio/${audio.id}`} element={<Audio id={audio.id} lang={lang}/>}/>);
    if (audio.slugs && audio.slugs.length>0) {
      audio.slugs.forEach((slug, i) => {
        res.push(<Route key={`/audio/${slug.slug}`} path={`/audio/${slug.slug}`} element={<Audio id={audio.id} lang={lang}/>}/>);
      });
    }
  });
  images.forEach((imgs, i) => {
    res.push(<Route key={`/images/${imgs.id}`} path={`/images/${imgs.id}`} element={<Images id={imgs.id} lang={lang}/>}/>);
    if (imgs.slugs && imgs.slugs.length>0) {
      imgs.slugs.forEach((slug, i) => {
        res.push(<Route key={`/images/${slug.slug}`} path={`/images/${slug.slug}`}>
            <Images id={imgs.id} lang={lang}/>
        </Route>);
      });
    }
  });
  multimedias.forEach((mul, i) => {
    res.push(<Route key={`/multimedia/${mul.id}`} path={`/multimedia/${mul.id}`} element={<Multimedia id={mul.id} lang={lang}/>}/>);
    if (mul.slugs && mul.slugs.length>0) {
      mul.slugs.forEach((slug, i) => {
        res.push(<Route key={`/multimedia/${slug.slug}`} path={`/multimedia/${slug.slug}`} element={<Multimedia id={mul.id} lang={lang}/>}/>);
      });
    }
  });
  res=[...res, ...localRoutes];
  return <Routes>
      {res}
  </Routes>;
};

export default AllRoutes;
