import React from "react";
import './MultimediaPlayer.scss';
import IiifPlayer from './Iiif/IiifPlayer';
import IframePlayer from './Iframe/IframePlayer';
import DiapoSonPlayer from './DiapoSonPlayer/DiapoSonPlayer';

function MultimediaPlayer({item,config}) {
  let player='';
  console.log(config);
  if (config.type==='iiif') player=<IiifPlayer url={config.url}/>;
  if (config.type==='iframe') player=<IframePlayer title={item.name} url={config.url} refresh={config.refresh}/>
  if (config.type==='diapoSon') player=<DiapoSonPlayer title={item.name} images={item.files.filter(o=>o.directus_files_id.type.startsWith('image/'))} audio={item.files.find(o=>o.directus_files_id.type.startsWith('audio/'))}/>
  return <div className="multimedia-player" style={{width:item.width+"%",paddingBottom:(item.ratio*item.width)+"%"}}>
      <div>{player}</div>
  </div>;
}

export default MultimediaPlayer;
