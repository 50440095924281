import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Multimedia.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import MultimediaPlayer from './MultimediaPlayer';
import Suggestion from 'components/Suggestion';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';

function Multimedia({id}) {
  const { translate, setTick } = useContext(SettingsContext);
  const { data } = useQuery(Queries.multimedia,{
    variables: { id:parseInt(id) },
  });
  useEffect(()=>setTick(t=>t+1),[data,setTick]);
  const item=data ? data.multimedias[0] : {};
  const cartoucheDataTop=[
    ...Utils.renderExpos(item),
    {key:item.type,value:translate(item,'name')},
    Utils.renderArtistes(item,true),
    {key_tr:translate(item,'stat_key'),value:translate(item,'stat_value')},
  ];
  const cartoucheDataBottom=[
    {text:translate(item,'texte')},
    {ps:translate(item,'ps')},
    Utils.renderTags(item),
  ];
  return (<div className="multimedia">
      <div className="main-container">
          <Grid container>
              <Grid item xs={12} md={3}>
                  {data && <CartoucheInfos data={cartoucheDataTop}/>}
                  {data && <Hidden smDown><CartoucheInfos data={cartoucheDataBottom}/></Hidden>}
              </Grid>
              <Grid item xs={12} md={9}>
                  {data && <MultimediaPlayer item={item} config={JSON.parse(item.config)}/>}
              </Grid>
              <Grid item xs={12}>
                  {data && <Hidden mdUp><CartoucheInfos data={cartoucheDataBottom}/></Hidden>}
              </Grid>
          </Grid>
          <Suggestion currentItem={item}/>
      </div>
  </div>);
}
export default Multimedia;
