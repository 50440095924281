import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';
import './Footer.scss';

function FooterClone() {
  const { settings, translate, scrollY, filter, tick } = useContext(SettingsContext);
  const [ anim, setAnim ] = useState(true);
  const [ show, setShow ] = useState(true);
  let classes="footer clone";
  if (scrollY===0) classes+=" top";
  const scrollDown=()=>{
      setAnim(false);
      setTimeout(()=>{
        const footer=document.getElementById('footer');
        const rect=footer.getBoundingClientRect();
        window.scroll(0,rect.top);
        setTimeout(()=>setAnim(true),1000);
      },1);
  }
  useEffect(()=>{
    const footer=document.getElementById('footer');
    if (footer) setShow(footer.getBoundingClientRect().top>window.innerHeight);
  },[filter,tick]);
  if (show) classes+=" show";
  if (anim) classes+=" anim";
  return <div className={classes} onClick={scrollDown}>
      <div className="footer-inner">
        <div className="footer-baseline" dangerouslySetInnerHTML={{__html:translate(settings,'plateforme_pitch')}}></div>
      </div>
  </div>;
}

export default FooterClone;
