import React, {useContext} from "react";
import './Type.scss';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  useNavigate,
} from "react-router-dom";
import shim from 'string.prototype.matchall/shim';
shim();

function Type({type,toggle=false}) {
  const { filter, setFilter } = useContext(SettingsContext);
  const { search, ...otherFilter } = filter;
  const navigate = useNavigate();
  //type
  const typeRegex=/:([^\s]+)/g;
  const typeRes=Utils.accentsTidyLw(search).matchAll(typeRegex) || [];
  const types=[...typeRes].map((t)=>t[1]);
  const active=types.reduce((acc,t)=>{
      return acc || Utils.accentsTidyLw(t.replace(/\s/g,'_'))===Utils.accentsTidyLw(type.type.replace(/\s/g,'_'));
  },false);
  const toggleType=()=>{
      const escapedType=Utils.accentsTidy(type.type.replace(/\s/g,'_'));
      if (!active) {
          if (window.location.pathname!=='/') {
            navigate("/");
          }
          setFilter({search:(search+" :"+escapedType).trim(), ...otherFilter});
      } else {
          setFilter({search:search.replace(":"+escapedType,'').trim(), ...otherFilter});
      }
  };
  let classes="menu-left-item";
  if (active) classes+=" active";
  const picto=Utils.pictos[Utils.type2icon(type.type)];
  const Picto=picto ? picto : ()=>'';
  return <div className={classes} onClick={toggleType}>
      {type.titre}
      <div className="picto"><Picto/></div>
  </div>;
}

export default Type;
