import React, { useState, useEffect } from "react";
import './AudioPlayer.scss';

function AudioPlayer({son}) {
  const [ preview,setPreview ] = useState('');
  useEffect(()=>{
    const soundcloudRegex=/^(?:(https?):\/\/)?(?:(?:www|m)\.)?(soundcloud\.com|snd\.sc)\/(.*)$/gm;
    const soundcloudMatches=soundcloudRegex.test(son);
    if (soundcloudMatches) {
    	 const query=`https://soundcloud.com/oembed?format=json&url=${son}`;
    	 fetch(query)
    	 .then((response)=>{
    		 response.json()
    		 .then((json)=>{
    			 setPreview(json.html || '');
    		 });
    	 });
    }
  },[son, setPreview]);
  return <div className="audio-player">
    <div dangerouslySetInnerHTML={{__html:preview}}></div>
  </div>;
}

export default AudioPlayer;
