import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Audio.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import AudioPlayer from './AudioPlayer';
import Suggestion from 'components/Suggestion';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Utils from 'utils/Utils';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';

function Audio({id}) {
  const { translate, setTick } = useContext(SettingsContext);
  const { data } = useQuery(Queries.audio,{
    variables: { id:parseInt(id) },
  });
  useEffect(()=>setTick(t=>t+1),[data,setTick]);
  const item=data ? data.audios[0] : {};
  const cartoucheDataTop=[
    ...Utils.renderExpos(item),
    {key:'audio',value:translate(item,'name')},
    Utils.renderArtistes(item,true),
    {key:'duree',value:item.duree},
  ];
  const cartoucheDataBottom=[
    {text:translate(item,'texte')},
    {ps:translate(item,'ps')},
    Utils.renderTags(item),
  ];
  return (<div className="audio">
      <div className="main-container">
          <Grid container>
              <Grid item xs={12} md={3}>
                  {data && <CartoucheInfos data={cartoucheDataTop}/>}
                  {data && <Hidden smDown><CartoucheInfos data={cartoucheDataBottom}/></Hidden>}
              </Grid>
              <Grid item xs={12} md={9}>
                  {item && <AudioPlayer son={item.son}/>}
              </Grid>
              <Grid item xs={12}>
                  {data && <Hidden mdUp><CartoucheInfos data={cartoucheDataBottom}/></Hidden>}
              </Grid>
          </Grid>
          <Suggestion currentItem={item}/>
      </div>
  </div>);
}
export default Audio;
