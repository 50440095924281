import React, { useContext } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';
import './Footer.scss';
import Grid from '@mui/material/Grid';
import FooterClone from 'components/Footer/FooterClone';

function Footer() {
  const { t, settings, translate } = useContext(SettingsContext);
  return <><div className="footer" id="footer">
      <div className="footer-inner">
          <div className="footer-baseline" dangerouslySetInnerHTML={{__html:translate(settings,'plateforme_pitch')}}></div>
          <div className="footer-plus">
              <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                      <h3>{t('a-propos-de-playlist')}</h3>
                  </Grid>
                  <Grid item xs={12} md={3}>
                      <div className="footer-desc" dangerouslySetInnerHTML={{__html:translate(settings,'plateforme_desc_1')}}></div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                      <div className="footer-desc" dangerouslySetInnerHTML={{__html:translate(settings,'plateforme_desc_2')}}></div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                      <div className="footer-desc" dangerouslySetInnerHTML={{__html:translate(settings,'plateforme_desc_3')}}></div>
                  </Grid>
              </Grid>
          </div>
      </div>
  </div>
  <FooterClone/>
  </>;
}

export default Footer;
