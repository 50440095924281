import React from "react";
import './VideoPlayer.scss';

function VideoPlayer({video}) {
  let res='';
	//youtube
  const youtubeRegex=/^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9_-]{7,15})(?:[?&][a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)*$/gm;
	const youtubeMatches=youtubeRegex.exec(video);
	if (youtubeMatches && youtubeMatches[1]) {
			res=`<iframe style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;" src="https://www.youtube.com/embed/${youtubeMatches[1]}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
	}
  //vimeo
  const vimeoRegex=/^(?:https?:)?(?:\/\/)?(www\.)?vimeo\.com\/(\w*\/)*(([a-z]{0,2}-)?\d+)$/gm;
  const vimeoMatches=vimeoRegex.exec(video);
  if (vimeoMatches && vimeoMatches[3]) {
      res=`<iframe style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;" src="https://player.vimeo.com/video/${vimeoMatches[3]}?title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
  }
  return <div className="video-player">
    <div dangerouslySetInnerHTML={{__html:res}}></div>
  </div>;
}

export default VideoPlayer;
