import React, {useRef} from "react";
import './IframePlayer.scss';
import Fullscreen from 'components/Fullscreen';

function IframePlayer({title,url,ratio="56.25%",refresh=false}) {
  const refIframe=useRef();
  const handleRefresh=()=>{
      if (refresh) refIframe.current.src=url;
  }
  return <> 
    <iframe title={title} src={url} ref={refIframe}/>
    <Fullscreen onToggle={handleRefresh}/>
  </>;
}

export default IframePlayer;
