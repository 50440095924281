import React from "react";
import './Visuel.scss';
import Conf from 'utils/Conf';

function Visuel({visuel}) {
  return <div className="visuel">
      {visuel && <img alt={visuel.title} src={`${Conf.apiUrl}/assets/${visuel.id}?key=16-9-crop-small`}/>}
  </div>;
}

export default Visuel;
