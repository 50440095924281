import React, { useContext, useEffect } from "react";
import { useQuery } from '@apollo/client';
import './Texte'
import CartoucheInfos from 'components/CartoucheInfos';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Suggestion from 'components/Suggestion';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';
import PDFPlayer from './PDFPlayer';

function Texte({id}) {
  const { translate, setTick } = useContext(SettingsContext);
  const { data } = useQuery(Queries.texte,{
    variables: { id:parseInt(id) },
  });
  useEffect(()=>setTick(t=>t+1),[data,setTick]);
  const item=data ? data.textes[0] : {};
  const cartoucheDataTop=[
      ...Utils.renderExpos(item),
      {key:'texte',value:translate(item,'name')},
      Utils.renderArtistes(item,true),
      {key:'nombre-de-caracteres',value:item.nb_chars},
  ];
  const cartoucheDataBottom=[
      {text:translate(item,'texte')},
      {ps:translate(item,'ps')},
      Utils.renderTags(item),
  ];
  return (<div className="textes">
      <div className="main-container">
          <Grid container>
              <Grid item xs={12} md={3}>
                  {data && <CartoucheInfos data={cartoucheDataTop}/>}
                  {data && <Hidden smDown><CartoucheInfos data={cartoucheDataBottom}/></Hidden>}
              </Grid>
              <Grid item xs={12} md={9}>
                {item.file && <PDFPlayer pdf={item.file}/>}
              </Grid>
              <Grid item xs={12}>
                  {data && <Hidden mdUp><CartoucheInfos data={cartoucheDataBottom}/></Hidden>}
              </Grid>
          </Grid>
          <Suggestion currentItem={item}/>
      </div>
  </div>);
}
export default Texte;
