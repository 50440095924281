import { gql } from '@apollo/client';
const queries={
  audio: gql`
    query Audio($id: Float){
      audios(filter: { id : { _eq: $id}}) {
        id
        name
        duree
        texte
        ps
        son
        expositions{
          expositions_id {
            id
            status
            title
            date_from
            date_to
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
          }
        }
        translations {
          languages_code {
            code
          }
          name
          texte
          ps
        }
        slugs {
          slug
        }
        tags {
          tags_id {
            id
            tag
            translations {
              languages_code {
                code
              }
              tag
            }
          }
        }
        artistes {
          artistes_id {
            id
            name
          }
        }
      }
    }
  `,
  exposition:gql`
  query Exposition($id: Float){
    expositions(filter: { id : { _eq: $id}}) {
      id
      titre
      type
      descriptif
    }
  }`,
  images:gql`
    query Images($id: Float){
      images(filter: { id : { _eq: $id}}) {
        id
        name
        texte
        ps
        expositions{
          expositions_id {
            id
            status
            title
            date_from
            date_to
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
          }
        }
        translations {
          languages_code {
            code
          }
          name
          texte
          ps
        }
        slugs {
          slug
        }
        tags {
          tags_id {
            id
            tag
            translations {
              languages_code {
                code
              }
              tag
            }
          }
        }
        artistes {
          artistes_id {
            id
            name
          }
        }
        diaporama (sort:["sort"]){
          id
          directus_files_id {
            id
            title
            description
          }
        }
      }
    }
  `,
  texte:gql`
    query Textes($id: Float){
      textes(filter: { id : { _eq: $id}}) {
        id
        name
        texte
        ps
        file {
          id
          title
        }
        expositions{
          expositions_id {
            id
            status
            title
            date_from
            date_to
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
          }
        }
        translations {
          languages_code {
            code
          }
          name
          texte
          ps
        }
        slugs {
          slug
        }
        tags {
          tags_id {
            id
            tag
            translations {
              languages_code {
                code
              }
              tag
            }
          }
        }
        artistes {
          artistes_id {
            id
            name
          }
        }
      }
    }
  `,
  langswitch:gql`
    query LangSwitch{
      languages {
        code
      }
    }
  `,
  mainlist:gql`
    query MainList{
        videos(filter:{ status: { _eq: "published" }}) {
            id
            name
            visuel {
              id
              title
            }
            duree
            date_created
            expositions{
              expositions_id {
                id
                status
                title
                date_from
                date_to
                slugs (sort:["-date_created"], limit:1) {
                  slug
                }
              }
            }
            tags {
              tags_id {
                tag
              }
            }
            translations {
              languages_code {
                code
              }
              name
              texte
            }
            artistes {
              artistes_id {
                id
                name
              }
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
        }
        audios(filter:{ status: { _eq: "published" }}) {
            id
            name
            visuel {
              id
              title
            }
            duree
            date_created
            expositions{
              expositions_id {
                id
                status
                title
                date_from
                date_to
                slugs (sort:["-date_created"], limit:1) {
                  slug
                }
              }
            }
            tags {
              tags_id {
                tag
              }
            }
            translations {
              languages_code {
                code
              }
              name
              texte
            }
            artistes {
              artistes_id {
                id
                name
              }
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
        }
        textes(filter:{ status: { _eq: "published" }}) {
          id
          name
          visuel {
            id
            title
          }
          nb_chars
          date_created
          expositions{
            expositions_id {
              id
              status
              title
              date_from
              date_to
              slugs (sort:["-date_created"], limit:1) {
                slug
              }
            }
          }
          tags {
            tags_id {
              tag
            }
          }
          translations {
            languages_code {
              code
            }
            name
            texte
          }
          artistes {
            artistes_id {
              id
              name
            }
          }
          slugs (sort:["-date_created"], limit:1) {
            slug
          }
      }
      images(filter:{ status: { _eq: "published" }}) {
            id
            name
            visuel {
              id
              title
            }
            date_created
            expositions{
              expositions_id {
                id
                status
                title
                date_from
                date_to
                slugs (sort:["-date_created"], limit:1) {
                  slug
                }
              }
            }
            tags {
              tags_id {
                tag
              }
            }
            translations {
              languages_code {
                code
              }
              name
              texte
            }
            artistes {
              artistes_id {
                id
                name
              }
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
            diaporama {
              id
            }
        }
        multimedias(filter:{ status: { _eq: "published" }}) {
            id
            name
            status
            visuel {
              id
              title
            }
            stat_key
            stat_value
            date_created
            type
            expositions{
              expositions_id {
                id
                status
                title
                date_from
                date_to
                slugs (sort:["-date_created"], limit:1) {
                  slug
                }
              }
            }
            tags {
              tags_id {
                tag
              }
            }
            translations {
              languages_code {
                code
              }
              name
              stat_key
              stat_value
            }
            artistes {
              artistes_id {
                id
                name
              }
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
        }
    }
  `,
  menuleft:gql`
  query MenuLeft {
      expositions (filter:{ status: { _eq: "published" }}){
        id
        status
        title
        date_from
        date_to
        slugs (sort:["-date_created"], limit:1) {
          slug
        }
        o_multimedia {
          multimedias_id {
            status
          }
        }
        o_audios {
          audios_id {
            status
          }
        }
        o_videos {
          videos_id {
            status
          }
        }
        o_images {
          images_id {
            status
          }
        }
      }
  }`,
  multimedia:gql`
    query Multimedia($id: Float){
        multimedias(filter: { id : { _eq: $id}}) {
          id
          name
          stat_key
          stat_value
          texte
          ps
          config
          width
          ratio
          type
          files {
            directus_files_id {
              id
              title
              type
            }
          }
          expositions{
            expositions_id {
              id
              status
              title
              date_from
              date_to
              slugs (sort:["-date_created"], limit:1) {
                slug
              }
            }
          }
          tags {
            tags_id {
              id
              tag
              translations {
                languages_code {
                  code
                }
                tag
              }
            }
          }
          translations {
            languages_code {
              code
            }
            name
            stat_key
            stat_value
            texte
            ps
          }
          slugs {
            slug
          }
          tags {
            tags_id {
              tag
            }
          }
          artistes {
            artistes_id {
              id
              name
            }
          }
        }
    }
  `,
  allroutes:gql`
    query AllRoutes {
        videos(filter: { status : { _eq: "Published"}}) {
          id
          name
          slugs {
              slug
              date_created
          }
        }
        audios(filter: { status : { _eq: "Published"}}) {
          id
          name
          slugs {
              slug
              date_created
          }
        }
        textes(filter: { status : { _eq: "Published"}}) {
          id
          name
          slugs {
              slug
              date_created
          }
        }
        images(filter: { status : { _eq: "Published"}}) {
          id
          name
          slugs {
              slug
              date_created
          }
        }
        multimedias(filter: { status : { _eq: "Published"}}) {
          id
          name
          slugs {
              slug
              date_created
          }
        }
    }
  `,
  suggestion:gql`
    query MainList{
        videos(filter:{ status: { _eq: "published" }}) {
            id
            name
            duree
            date_created
            artistes {
              artistes_id {
                id
                name
              }
            }
            translations {
              languages_code {
                code
              }
              name
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
        }
        audios(filter:{ status: { _eq: "published" }}) {
            id
            name
            duree
            date_created
            artistes {
              artistes_id {
                id
                name
              }
            }
            translations {
              languages_code {
                code
              }
              name
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
        }
        images(filter:{ status: { _eq: "published" }}) {
            id
            name
            date_created
            artistes {
              artistes_id {
                id
                name
              }
            }
            translations {
              languages_code {
                code
              }
              name
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
            diaporama {
              id
            }
        }
        multimedias(filter:{ status: { _eq: "published" }}) {
            id
            name
            type
            stat_key
            stat_value
            date_created
            tags {
              tags_id {
                tag
              }
            }
            translations {
              languages_code {
                code
              }
              name
              stat_key
              stat_value
            }
            artistes {
              artistes_id {
                id
                name
              }
            }
            slugs (sort:["-date_created"], limit:1) {
              slug
            }
        }
    }
  `,
  video:gql`
    query Video($id: Float){
        videos(filter: { id : { _eq: $id}}) {
          id
          name
          duree
          video
          texte
          ps
          expositions{
            expositions_id {
              id
              status
              title
              date_from
              date_to
              slugs (sort:["-date_created"], limit:1) {
                slug
              }
            }
          }
          tags {
            tags_id {
              id
              tag
              translations {
                languages_code {
                  code
                }
                tag
              }
            }
          }
          translations {
            languages_code {
              code
            }
            name
            texte
            ps
          }
          slugs {
            slug
          }
          tags {
            tags_id {
              tag
            }
          }
          artistes {
            artistes_id {
              id
              name
            }
          }
        }
    }
  `,
  settings:gql`
    query Settings{
        settings {
          contrast_color
          sitename
          plateforme_pitch
          plateforme_desc_1
          plateforme_desc_2
          plateforme_desc_3
          translations {
            languages_code {
              code
              name
            }
            plateforme_pitch
            plateforme_desc_1
            plateforme_desc_2
            plateforme_desc_3
          }
        }
        interface {
          key
          value
          text
          translations {
            languages_code {
              code
              name
            }
            value
            text
          }
        }
    }
  `,
}
export default queries;
